import { gql } from '@apollo/client';
// eslint-disable-line import/no-extraneous-dependencies
export const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $discussionId: ID!
    $messageId: ID
    $value: String!
    $fields: [Json!]
    $isCensored: Boolean
    $uuid: String
  ) {
    createMessage(
      discussionId: $discussionId
      messageId: $messageId
      value: $value
      fields: $fields
      isCensored: $isCensored
      uuid: $uuid
    ) {
      id
      isVisible
      createdAt
      value
      likes
      isCensored
      fromModerator
      uuid
      fieldValues {
        id
        value
        field {
          id
          formLabel {
            type
            label
          }
        }
      }
      message {
        id
        answers {
          id
        }
      }
      discussion {
        id
      }
    }
  }
`;

export const LIKE_MESSAGE = gql`
  mutation LikeMessage($id: ID!, $action: String!) {
    likeMessage(id: $id, action: $action) {
      id
      likes
      discussion {
        id
      }
    }
  }
`;
