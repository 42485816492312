import { loadScript } from './confidentiality';

export const loadAuth0Lock = ({router, t, color, fields = [], isInvitation, showLogin}) => {
  const { email } = router.query;
  return new Promise((resolve, reject) => {
    loadScript('https://cdn.auth0.com/js/lock/11.34.2/lock.min.js', () => {
      try {
        const baseUrl = {};
        let domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
        if (process.env.NODE_ENV !== 'local') {
          baseUrl.configurationBaseUrl = 'https://cdn.eu.auth0.com';
          domain = process.env.NEXT_PUBLIC_AUTH0_CUSTOM_DOMAIN;
        }
        const lock = new window.Auth0Lock(
          process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
          domain,
          {
            container: 'auth',
            allowSignUp: !isInvitation,
            language: router.locale,
            ...baseUrl,
            autoclose: true,
            avatar: null,
            ...(email && {
              prefill: {
                email
              },
            }),
           
            rememberLastLogin: false,
            loginAfterSignUp: true,
            //initialScreen: 'forgotPassword',
            initialScreen: router.asPath.includes('showSignIn') || showLogin
              ? 'login'
              : router.asPath.includes('showSignUp') || !isInvitation
              ? 'signUp'
              : 'login',
            showTerms: true,
            auth: {
              redirect: false,
              redirectUrl: `${window.location.origin}/permissionCallback`,
              responseMode: 'form_post',
              responseType: 'token',
              autoParseHash: true
            },
            theme: {
              logo: '',
              primaryColor: color
            },
            languageDictionary: {
              title: '',
              signUpTitle: '',
              error: {
                signUp: {
                  user_exists: t('account-already-exists')
                }
              }
            },
            additionalSignUpFields:
              fields &&
              fields.map((field) => ({
                name: field.label,
                placeholder: t('your') + ' ' + t(field.label),
                // icon: "https://example.com/assests/address_icon.png",
                validator: (value) =>
                  field.isMandatory ? value.length > 1 : true
              }))
          }
          //   {
          //     container: 'auth',
          //     allowSignUp: false,
          //     language: router.locale,
          //     ...baseUrl,
          //     autoclose: true,
          //     avatar: null,
          //     rememberLastLogin: false,
          //     loginAfterSignUp: false,
          //     showTerms: true,
          //     auth: {
          //       redirect: false,
          //       redirectUrl: `${window.location.origin}/permissionCallback`,
          //       responseType: 'token',
          //       autoParseHash: true
          //     },
          //     theme: {
          //       logo: '',
          //       primaryColor: color
          //     },
          //     languageDictionary: {
          //       title: '',
          //       signUpTitle: '',
          //       error: {
          //         signUp: {
          //           user_exists: t('account-already-exists')
          //         }
          //       }
          //     }
          //   }
        );

        resolve(lock);
      } catch (error) {
        reject(error);
      }
    });
  });
};
