import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { formDataState } from '../../recoil/formData';
import { privacyTypeState } from '../../recoil/privacyType';
import { loadAuth0Lock } from '../functions/auth0';
import { getFormInfos } from '../functions/discussion';
import {
  formatDiscussionFields,
  getFormDataCookie,
  setFormDataCookie
} from '../functions/fieldsValues';

const useGetFormDatas = ({ discussion }) => {
  const [auth0Profile, setAuth0Profile] = useState([]);
  const [displayKey, setDisplayKey] = useState(false);
  const [hasMandatoryField, setHasMandatoryField] = useState();
  const [hasMissingMandatoryField, setHasMissingMandatoryField] =
    useState(false);
  const [formData, setFormData] = useRecoilState(formDataState);
  const [privacyType] = useRecoilState(privacyTypeState);
  const [isAnonAllowed, setIsAnonAllowed] = useState();

  const { t } = useTranslation('common');
  const router = useRouter();

  useEffect(() => {
    if (!privacyType || hasMandatoryField === undefined) return;
    const { anonAllowed } = getFormInfos(privacyType, hasMandatoryField);
    setIsAnonAllowed(anonAllowed);
  }, [hasMandatoryField, privacyType]);

  useEffect(() => {
    if (auth0Profile.length) return;

    const token = parseCookies()['contentToken'];
    if (!token) return;

    loadAuth0Lock({router, t}).then((lock) => {
      lock.getUserInfo(token, (error, profile) => {
        if (!error) {
          setAuth0Profile(profile);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (!privacyType || !discussion?.fields) return;

    const formData = getFormDataCookie();

    const data = formatDiscussionFields({
      discussionFields: discussion.fields,
      ...(auth0Profile && { auth0Profile }),
      ...(formData &&
        //if the privacy has changed, we need to reset the form data
        formData.privacyType === privacyType && { formData: formData.data })
    });

    const newData = {
      ...formData,
      privacyType,
      data
    };
    setFormDataCookie(newData);
    setFormData(newData);
  }, [discussion, auth0Profile, privacyType]);

  useEffect(() => {
    if (!formData?.data.length) return;
    const displayKeyTmp = formData.data.find(
      (field) => field.isDisplayKey
    )?.value;
    if (displayKeyTmp) {
      setDisplayKey(displayKeyTmp);
    } else {
      setDisplayKey(t('settings'));
    }
  }, [formData]);

  useEffect(() => {
    if (!formData?.data?.length) return;
    const hasMandatoryFieldTmp = formData.data.some((e) => e.isMandatory);
    setHasMandatoryField(hasMandatoryFieldTmp);
    const hasMissingMandatoryFieldTmp = formData.data.some(
      (e) => e.isMandatory && !e.value
    );
    setHasMissingMandatoryField(hasMissingMandatoryFieldTmp);
  }, [formData]);

  return {
    formData,
    setFormData,
    displayKey,
    setDisplayKey,
    hasMandatoryField,
    hasMissingMandatoryField,
    isAnonAllowed
  };
};

export default useGetFormDatas;
