import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import filter from 'leo-profanity';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { discussionState } from '../../../recoil/discussion';
import { formDataState } from '../../../recoil/formData';
import { scrollToLastMsg } from '../../../utils/functions/discussion';
import {
  destroyFormDataCookie,
  formatFieldsData,
  getFormDataCookie,
  setFormDataCookie
} from '../../../utils/functions/fieldsValues';
import useGetFormDatas from '../../../utils/hooks/useGetFormDatas';
import useUuid from '../../../utils/hooks/useUuid';
import { useCreateMessage } from '../../../utils/store/discussion';
import DiscussionFormFields from '../DiscussionFormField';
import {getCookies} from 'nookies';
import Cookies from 'js-cookie';

const { TextArea } = Input;

const DiscussionForm = function ({
  discussion,
  parentMessage = null,
  setAnswerForm,
  theme,
  isAnswerForm
}) {
  const [form] = Form.useForm();
  const [isShowingFields, setIsShowingFields] = useState(false);
  const [isAnon, setIsAnon] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const formRef = useRef(null);
  const answerInputRef = useRef();
  const { createMessage, loading } = useCreateMessage();

  const screens = useBreakpoint();
  const { t } = useTranslation('common');
  const router = useRouter();

  const [formData, setFormData] = useRecoilState(formDataState);

  const isQAndA = useRecoilValue(discussionState);

  const uuid = useUuid();

  const {
    hasMandatoryField,
    hasMissingMandatoryField,
    displayKey,
    setDisplayKey,
    isAnonAllowed
  } = useGetFormDatas({
    discussion
  });

  useEffect(() => {
    if (filterLoaded || !discussion.isCensorship) return;
    const { locale } = router;
    if (locale === 'fr') {
      filter.loadDictionary('fr');
    } else if (locale === 'en') {
      filter.loadDictionary('en');
    }
    setFilterLoaded(true);
  }, [router, filterLoaded, discussion]);

  // useEffect(() => {
  //   if (!answerInputRef.current) return;
  //   answerInputRef.current.focus();
  // }, []);

  useEffect(() => {
    if (!formData?.data?.length) return;
    formData.data.forEach((item) => {
      const { id, value } = item;
      form.setFieldsValue({
        [id]: value
      });
    });
  }, [form, formData]);

  useEffect(() => {
    window.dispatchEvent(new Event('resizeUseTabHeight'));
  }, [isShowingFields]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resizeUseTabHeight'));
    }, 500);
  }, []);

  const handleChangeMessage = (e) => {
    const message = e.target.value;
    if (
      message.length <= 0 ||
      !hasMandatoryField ||
      !hasMissingMandatoryField
    ) {
      setIsShowingFields(false);
    } else {
      setIsShowingFields(true);
    }
  };

  const handleShowField = () => {
    setIsShowingFields((state) => !state);
  };

  const handleAnonymous = (e) => {
    if (!isShowingFields) return;
    e.preventDefault();
    e.stopPropagation();
    destroyFormDataCookie();
    form.resetFields();
    setIsAnon(true);
    setDisplayKey('Anonyme');
    setIsShowingFields(false);
  };

  const handleUpdateCrendentials = async () => {
    const fieldsToValidate = formData.data.map((item) => item.id);
    const values = await form.validateFields(fieldsToValidate);
    const currentFormData = getFormDataCookie();
    const newFormData = Object.keys(values).map((key) => ({
      ...(currentFormData?.data && {
        ...currentFormData.data.find((e) => e.id === key)
      }),
      value: values[key]
    }));
    const newData = {
      ...currentFormData,
      data: newFormData
    };
    setFormData(newData);
    setFormDataCookie(newData);
    return newFormData;
  };

  const messageFieldId = discussion?.fields?.find(
    (field) => field?.formLabel.label === 'email'
  )?.id;

  const emailFromCookie = parseCookies()
  emailFromCookie.email = emailFromCookie.email.replace(/"/g, "");

  const handleFinish = async (values) => {
    if (!uuid) {
      message.error(t('message-uuid'));
      return;
    }
    const data = await handleUpdateCrendentials();

    const fieldsData = formatFieldsData(data);

    const isCensored = discussion.isCensorship && filter.check(values.message);

    if (isCensored) {
      message.info({
        content: t('message-profanity'),
        duration: 5,
        className: 'custom-class',
        style: {
          position: 'absolute',
          bottom: !screens.md ? '90px' : '32px',
          right: '5vw'
        }
      });
    }

    await createMessage({
      discussionId: discussion.id,
      value: values.message,
      fields: [{ messageFieldId, value: emailFromCookie.email }] ,
      messageId: parentMessage,
      isCensored,
      uuid
    });

    form.resetFields(['message']);
    !setAnswerForm && answerInputRef.current.focus({ cursor: 'start' });
    setIsShowingFields(false);
    setAnswerForm && setAnswerForm(false);
    setTimeout(() => {
      window.dispatchEvent(new Event('resizeUseTabHeight'));
      scrollToLastMsg();
    }, 200);
  };

  const onFinishFailed = () => {
    setIsShowingFields(true);
  };

  const handleCloseForm = () => {
    setAnswerForm && setAnswerForm(false);
  };

  return (
    <div ref={formRef} className="form">
      {setAnswerForm && (
        <div className="close-form" onClick={handleCloseForm}>
          <CloseOutlined />
        </div>
      )}
      <Form
        onFinish={handleFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        data-cy="form"
        layout="vertical"
      >
        <div className="formDark form__container">
          {/* {formData?.data?.length > 0 && (
            <DiscussionFormFields
              theme={theme}
              displayKey={displayKey}
              formData={formData.data}
              showFields={isShowingFields}
              handleShowField={handleShowField}
              handleAnonymous={handleAnonymous}
              hasMandatoryField={hasMandatoryField}
              isAnonymous={isAnon}
              isAnswerForm={isAnswerForm}
              isAnonAllowed={isAnonAllowed}
            />
          )} */}
          <input disabled  name="uuid" value={emailFromCookie.email} style={{
            width: '70%',
            margin:"0px 0px 10px 0px",
            color: 'grey',
          }}/>
          <div className="form__footer">
            <Form.Item
              style={{
                width: '100%',
                marginBottom: 0
              }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('required-form')
                }
              ]}
              name="message"
            >
              <TextArea
                data-cy={
                  !parentMessage
                    ? 'discussion-principalInput'
                    : 'discussion-answerInput'
                }
                ref={answerInputRef}
                onChange={handleChangeMessage}
                onPressEnter={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  form.submit();
                }}
                autoSize={{ minRows: isQAndA && screens.sm ? 5 : 1 }}
                showCount
                maxLength={500}
                placeholder={t(
                  isQAndA ? 'placeholder-qa' : 'placeholder-message'
                )}
                style={{
                  fontSize: 12
                }}
              />
            </Form.Item>

            <Button
              data-cy={
                !parentMessage
                  ? 'discussion-sendMessage'
                  : 'discussion-sendAnswerMessage'
              }
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{
                marginLeft: 8,
                fontWeight: 500
              }}
            >
              {t('send')}
            </Button>
          </div>
        </div>
      </Form>
      <style jsx>{`
        .form {
          background: ${theme === 'dark' ? '#252525' : '#f7f7f7'};
          width: 100%;
          padding: ${screens.sm ? '8px 16px 16px' : '8px'};
          width: ${parentMessage ? 'calc(100% + 48px)' : ''};
          margin-left: ${parentMessage ? '-24px' : ''};
          position: relative;
          background: ${theme === 'dark' ? '#252525' : '#f7f7f7'};
        }
        .close-form {
          position: absolute;
          top: 8px;
          right: 16px;
          z-index: 10;
          cursor: pointer;
        }
        .form__container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .form__container > * {
          width: 100%;
        }
        .form__footer {
          display: flex;
          align-items: flex-end;
        }
      `}</style>
      <style jsx global>{`
        .ant-form-item-explain {
          display: none;
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
          padding: 0 !important;
          margin: 0;
        }
        .ant-collapse-ghost
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          padding: 0;
        }
        .ant-form-vertical .ant-form-item-label > label {
          opacity: 0.5;
          font-size: 12px;
        }
        .ant-form-vertical .ant-form-item-label,
        .ant-col-24.ant-form-item-label,
        .ant-col-xl-24.ant-form-item-label {
          padding-bottom: 0;
        }
        #message {
          //background: rgba(33, 150, 243, 0.15);
        }
        #message::placeholder {
          //color: rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </div>
  );
};

export default DiscussionForm;
