import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { discussionState } from '../../recoil/discussion';
import { privacyTypeState } from '../../recoil/privacyType';
import useTabHeight from '../../utils/hooks/useTabHeight';
import { GetDiscussionProperties } from '../../utils/store/discussion';
import { GET_UPDATED_DISCUSSION } from '../../utils/subscription/discussion';
import Confidentiality from '../Confidentiality';
import LoaderSpin from '../LoaderSpin';
import DiscussionFeed from './DiscussionFeed';
import DiscussionForm from './DiscussionForm';
import IconQA from './IconQA';
import { getPrivacyType } from '../../utils/functions/confidentiality';

const Discussion = function ({
  discussionId,
  discussionFromServer = false,
  errorFromServer = false,
  webTvId,
  playlistId,
  theme,
  color,
  isEmbed
}) {
  const [discussion, setDiscussion] = useState(discussionFromServer);
  const [error, setError] = useState();
  const [formVisible, setFormVisible] = useState(false);
  const [answerForm, setAnswerForm] = useState();
  const setPrivacyType = useSetRecoilState(privacyTypeState);
  const screens = useBreakpoint();
  const [isQAndA, setIsQAndA] = useRecoilState(discussionState);

  const { discussionFeedHeight, discussionFormHeight } = useTabHeight();

  const router = useRouter();

  const {
    data,
    loading,
    error: apolloError,
    subscribeToMore
  } = GetDiscussionProperties(discussionId, webTvId, playlistId /*, skip*/);

  const { ref, inView } = useInView({
    root: null,
    threshold: 0.05
  });

  useEffect(() => {
    setIsQAndA(discussion?.preset === 'QANDA');
    const privacity = getPrivacyType({
      content: discussion?.media || discussion?.live
    });
    setPrivacyType(privacity);
  }, [discussion]);

  useEffect(() => {
    if (!subscribeToMore || loading) return;
    const unsubscribe = subscribeToMore({
      document: GET_UPDATED_DISCUSSION,
      variables: {
        id: discussionId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return {
          ...prev,
          discussion: { ...prev.discussion, ...subscriptionData.discussion }
        };
      }
    });
    return () => unsubscribe();
  }, [subscribeToMore, loading, discussionId]);

  useEffect(() => {
    if (!data) return;
    setDiscussion(data);
    setError(null);
  }, [data, router]);

  useEffect(() => {
    if (!apolloError) return;
    setError(apolloError);
  }, [apolloError]);

  useEffect(() => {
    if (answerForm) {
      setFormVisible(false);
    } else {
      setFormVisible(inView);
    }
  }, [inView, answerForm]);

  if (loading && !discussionFromServer) return <LoaderSpin />;
  if (error && router.pathname === '/questionnaire/[discussionId]') {
    return (
      <Confidentiality
        errorData={error}
        contentId={discussionId}
        contentType="discussion"
      />
    );
  }

  return (
    <>
      <div id="feedContainer" className="feedContainer" ref={ref}>
        {discussion.shouldShowMessages && (
          <DiscussionFeed
            discussion={discussion}
            answerForm={answerForm}
            setAnswerForm={setAnswerForm}
            color={color}
            theme={theme}
            webTvId={webTvId}
            playlistId={playlistId}
          />
        )}
        {isQAndA && !discussion.shouldShowMessages && (
          <div className="qa__container">
            <IconQA color={color} />
            <p>{discussion?.placeholder}</p>
          </div>
        )}
      </div>

      <div className="form" id="discussionForm" style={{ zIndex: 2 }}>
        <DiscussionForm discussion={discussion} theme={theme} />
      </div>

      <style jsx>{`
        .feedContainer {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          overflow-x: ${screens.sm ? 'auto' : 'scroll'};
          height: ${screens.sm
            ? '500px'
            : !!webTvId
            ? '400px'
            : discussionFeedHeight};
          padding: 24px;
          padding: 24px 24px
            ${(screens.sm && formVisible) || !!webTvId || isEmbed
              ? discussionFormHeight
              : '8px'};
          transition: all 0.3s;
        }
        /* hide scrollbar but allow scrolling */
        .feedContainer {
          -ms-overflow-style: none; /* for Internet Explorer, Edge */
          scrollbar-width: none; /* for Firefox */
          overflow-y: scroll;
        }
        .feedContainer::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }
        .form {
          position: ${(screens.sm || !!webTvId) && !isEmbed ? 'absolute' : 'fixed'};
          background: ${theme === 'dark' ? '#252525' : '#f7f7f7'};
          bottom: 0;
          left: 0;
          opacity: ${formVisible ? '1' : '0'};
          transition: opacity 0.2s;
          pointer-events: ${formVisible ? 'initial' : 'none'};
          width: 100%;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        .qa__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin: 0 auto;
          padding: ${screens.sm ? '0 32px 32px' : '0 16px 16px'};
        }

        .qa__container p {
          font-family: Poppins;
          font-size: ${screens.sm ? '20px' : '16px'};
          font-weight: 300;
          line-height: 1.2;
          text-align: center;
        }
      `}</style>
      <style global jsx>{`
        .qa__container svg {
          max-width: 240px;
          max-height: 240px;
          opacity: 0.8;
        }
      `}</style>
    </>
  );
};

export default Discussion;
