import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription ($discussionId: ID!) {
    message(discussionId: $discussionId) {
      mutation
      data {
        id
        updatedAt
        createdAt
        value
        likes
        isVisible
        isArchived
        fromModerator
        isCensored
        isDeleted
        uuid
        moderatorContent {
          id
          pseudo
        }
        fieldValues {
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
              type
            }
          }
        }
        answers {
          id
          value
          likes
          updatedAt
          createdAt
          isVisible
          isArchived
          fromModerator
          isCensored
          isDeleted
          uuid
          moderatorContent {
            id
            pseudo
          }
          fieldValues {
            id
            value
            field {
              id
              isDisplayKey
              formLabel {
                label
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_UPDATED_DISCUSSION = gql`
  subscription GetUpdatedDiscussion($id: ID!) {
    discussion(id: $id) {
      id
      shouldShowMessages
      shouldAllowLike
      shouldAllowAnswer
      isEmbed
      preset
      isCensorship
      # moderator {
      #   id
      #   name
      # }
      fields {
        id
        isMandatory
        isDisplayKey
        isActive
        formLabel {
          id
          type
          label
        }
      }
      live {
        id
        title
      }
      media {
        id
        title
      }
    }
  }
`;

export const GET_UPDATED_MESSAGES_ANIMATEUR = gql`
  subscription($discussionId: ID!) {
    messageAnimateur(discussionId: $discussionId) {
      mutation
      data {
        id
        createdAt
        updatedAt
        value
        likes
        isDeleted
        fromModerator
        isVisible
        isArchived
        presenterOnly
        isShownToPresenter
        moderatorContent{
          id
          pseudo
        }
        fieldValues{
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
              type
            }
          }
        }
        answers{
          id
          value
          likes
          updatedAt
          createdAt
          isVisible
          isArchived
          isDeleted
          fromModerator
          isCensored
          isShownToPresenter
          uuid
          fieldValues {
            id
            value
            field {
              id
              isDisplayKey
              formLabel {
                label
                type
              }
            }
          }
        }
       
      }
    }
  }
`;
