import React from 'react';

function IconQA({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4000"
      height="4000"
      version="1.1"
      viewBox="0 0 4000 4000"
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 4000)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path
            fill={color}
            d="M16787.4 17952.9c324.6-3172.9-2013.7-5989.7-5222.8-6291.4-648.2-60.9-1282.3-14.6-1884.31 124.3-229.08 52.9-2532.14-1174-2442.19-960.3 235.26 558.9 792.62 1512 698.45 1566.6-1563.77 907-2574.51 2553.2-2770.46 4468.3-324.66 3172.9 2013.67 5989.7 5222.81 6291.4 3209.1 301.7 6073.8-2026 6398.5-5198.9"
          ></path>
          <path
            fill="#ddd"
            d="M13212.6 13592.1c-324.6-3172.9 2013.7-5989.67 5222.8-6291.37 648.2-60.93 1282.3-14.6 1884.3 124.29 229.1 52.85 2532.2-1174 2442.2-960.3-235.3 558.92-792.6 1512-698.4 1566.62 1563.7 906.95 2574.5 2553.26 2770.4 4468.26 324.7 3172.9-2013.6 5989.7-5222.8 6291.4-3209.1 301.7-6073.8-2025.9-6398.5-5198.9"
          ></path>
          <path
            fill="#fff"
            d="M9427.9 18249.8c0 298.7 0 298.7 191.78 603.7 191.78 308.1 191.78 308.1 559.62 509.3 367.9 201.2 367.9 201.2 858.3 201.2 455.9 0 455.9 0 804.9-169.8 349-166.6 349-166.6 537.6-455.9 191.8-289.2 191.8-289.2 191.8-628.8 0-267.2 0-267.2-110.1-468.4-106.9-201.2-106.9-201.2-257.8-349-147.7-144.6-147.7-144.6-534.4-490.4-106.9-97.5-106.9-97.5-173-173-62.8-72.3-62.8-72.3-94.3-135.2-31.4-59.7-31.4-59.7-50.3-122.6-15.7-59.7-15.7-59.7-50.3-213.8-59.7-326.9-59.7-326.9-374.1-326.9-163.5 0-163.5 0-276.7 106.9-110 106.9-110 106.9-110 317.5 0 264.1 0 264.1 81.7 455.9 81.8 194.9 81.8 194.9 216.9 339.5 135.2 147.8 135.2 147.8 364.7 349 201.3 176.1 201.3 176.1 289.3 264.1 91.2 91.2 91.2 91.2 150.9 201.2 62.9 110.1 62.9 110.1 62.9 239 0 251.5 0 251.5-188.7 424.4-185.5 172.9-185.5 172.9-481 172.9-345.8 0-345.8 0-509.3-176-163.5-173-163.5-173-276.7-512.5-106.9-355.3-106.9-355.3-405.55-355.3-176.06 0-176.06 0-298.68 122.6-119.47 125.8-119.47 125.8-119.47 270.4zm1534.3-3445.8c-191.8 0-191.8 0-336.4 122.6-141.5 125.8-141.5 125.8-141.5 349 0 198.1 0 198.1 138.3 333.3 138.3 135.2 138.3 135.2 339.6 135.2 198 0 198 0 333.2-135.2s135.2-135.2 135.2-333.3c0-220.1 0-220.1-141.5-345.8-141.4-125.8-141.4-125.8-326.9-125.8"
          ></path>
          <path
            fill="#fff"
            d="M18654.3 12512l-100.6 1505.9c-28.3 440.2-28.3 440.2-28.3 632 0 260.9 0 260.9 135.2 405.5 138.3 147.8 138.3 147.8 361.5 147.8 270.4 0 270.4 0 361.6-188.6 91.2-185.5 91.2-185.5 91.2-537.6 0-207.5 0-207.5-22-421.3l-135.2-1550c-22.1-276.7-22.1-276.7-94.4-424.5-72.3-147.7-72.3-147.7-238.9-147.7-169.8 0-169.8 0-235.8 141.5-66 144.6-66 144.6-94.3 437zm349-2068.8c-191.8 0-191.8 0-336.4 122.6-141.5 125.8-141.5 125.8-141.5 349 0 195 0 195 135.2 330.1 138.3 138.4 138.3 138.4 336.4 138.4 198 0 198 0 336.4-138.4 141.5-135.1 141.5-135.1 141.5-330.1 0-220 0-220-141.5-345.8-141.5-125.8-141.5-125.8-330.1-125.8"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconQA;
