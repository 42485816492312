import Icon from '@ant-design/icons';
import { Button, Collapse, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCog } from 'react-icons/fa';
import { GiSpy } from 'react-icons/gi';

const { Panel } = Collapse;

const DiscussionFormFields = ({
  displayKey,
  formData,
  showFields,
  handleShowField,
  handleAnonymous,
  theme,
  isAnswerForm = false,
  isAnonAllowed
}) => {
  const { t } = useTranslation('common');

  const handleOnChange = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resizeUseTabHeight'));
    }, 300);
  };

  const buttonStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1,
    height: 24
  };

  return (
    <div className="discussion-form-fields">
      <Collapse
        activeKey={showFields ? [displayKey] : []}
        onChange={handleOnChange}
        expandIcon={() => (
          <Button
            type="link"
            icon={
              <Icon
                style={{ fontSize: '1.25em' }}
                component={() => <FaUserCog />}
              />
            }
            data-cy="open-form-fields"
            onClick={handleShowField}
            style={{
              ...buttonStyles,
            }}
          >
            {displayKey}
          </Button>
        )}
        ghost
        style={{
          background: '#f7f7f7',
          width: '100%',
          margin: '0 0 6px'
        }}
      >
        <div className="logBtns">
          {isAnonAllowed && (
            <Button
              type="link"
              icon={
                <Icon
                  style={{ fontSize: '1.40em' }}
                  component={() => <GiSpy />}
                />
              }
              style={{
                ...buttonStyles,
                marginRight: 8,
                cursor: showFields ? 'pointer' : 'auto'
              }}
              onClick={handleAnonymous}
            >
              Anonyme
            </Button>
          )}
        </div>
        <Panel key={displayKey}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px 8px',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            {formData.map((field) => (
              <Form.Item
                key={field.id}
                name={field.id}
                disabled={field.isDisabled}
                size="small"
                rules={
                  field.isMandatory
                    ? [
                      {
                        required: true,
                        whitespace: true,
                        message: t('required-form')
                      }
                    ]
                    : ''
                }
                style={{
                  flexGrow: 1,
                  maxWidth: '50%',
                  margin: '2px 0 0'
                }}
              >
                <Input
                  data-cy={'field-' + field.label}
                  placeholder={t(field.label)}
                  disabled={field.isDisabled}
                  size="small"
                />
              </Form.Item>
            ))}
          </div>
        </Panel>
        <style global jsx>{`
          .logBtns {
            position: absolute;
            right: ${isAnswerForm ? '32px' : '16px'};
            top: 8px;
            z-index: 2;
            display: flex;
            justify-content: flex-end;
            opacity: ${showFields ? 1 : 0};
            transition: opacity 0.3s ease-in-out;
          }
          .discussion-form-fields .ant-collapse-header,
          .discussion-form-fields .ant-collapse-content-box {
            padding: 0;
          }
          .discussion-form-fields .ant-collapse-item {
            #background: #f7f7f7;
          }
          .discussion-form-fields .ant-form-item-control-input {
            min-height: 24px;
          }
          #message::placeholder {
            ${theme === 'dark' && 'color: rgba(255,255,255,0.6);'}
          }
        `}</style>
      </Collapse>
    </div>
  );
};

export default DiscussionFormFields;
