import { EnterOutlined, LikeFilled, LikeOutlined,CrownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import hexToRgba from 'hex-to-rgba';
import Cookies from 'js-cookie';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { getDisplayName } from '../../utils/functions/discussion';
import { useLikeMessage } from '../../utils/store/discussion';

dayjs.locale('fr'); // use locale globally
dayjs().locale('fr').format();

const Comment = ({
  messageId,
  content,
  datetime,
  likes,
  isChild = false,
  setAnswerForm,
  shouldAllowLike,
  shouldAllowAnswer,
  isNewMessage,
  color,
  messagesLiked = [],
  setMessagesLiked,
  message,
  // t
}) => {
  const { t } = useTranslation(['common']);
  const likeMessage = useLikeMessage();

  const isMessageLiked = messagesLiked.some((item) => item === messageId);

  const handleLike = async (messageId) => {
    likeMessage({
      id: messageId,
      action: isMessageLiked ? 'unlike' : 'like'
    }).then(({ error }) => {
      if (!error) {
        const messagesLikedTmp = isMessageLiked
          ? messagesLiked.filter((item) => item !== messageId)
          : [...messagesLiked, messageId];
        setMessagesLiked(messagesLikedTmp);
        Cookies.set('messageLiked', JSON.stringify(messagesLikedTmp));
      }
    });
  };
  const author = (message) => {
      return (
        <span
          style={{
            color: message?.fromModerator && hexToRgba(color, 0.7)
          }}
        >
          {message?.fromModerator && <CrownOutlined />}{' '}
          {getDisplayName(message,t)}
        </span>
      );
  };

  return (
    <section
      id={messageId}
      className="comment"
      data-cy={isChild ? 'comment-child' : 'comment'}
    >
      <p className="comment__content">
        {isChild && <EnterOutlined style={{ transform: 'scaleX(-1)' }} />}
        <span className="author" data-cy="author">
          {author(message)}
        </span>
        <span className="content" data-cy="contentMessage">
          <Linkify properties={{ target: '_blank' }}>{content}</Linkify>
        </span>

        <span className="datetime" data-cy="dateTime">
          {dayjs(datetime).fromNow()}
        </span>

        {shouldAllowLike && (
          <Button
            data-cy="discussion-likeButton"
            type="link"
            onClick={() => handleLike(messageId)}
            style={{
              height: 'inherit',
              width: 'inherit',
              fontSize: 10,
              padding: 0,
              marginRight: 8
            }}
            icon={
              isMessageLiked ? (
                <LikeFilled style={{ fontSize: 12 }} />
              ) : (
                <LikeOutlined style={{ fontSize: 12 }} />
              )
            }
          >
            {likes !== 0 && likes}
          </Button>
        )}
        {!isChild && shouldAllowAnswer && (
          <Button
            data-cy="answerButton"
            type="link"
            onClick={() => setAnswerForm(messageId)}
            style={{
              display: 'inline',
              width: 'inherit',
              linkTransform: 'uppercase',
              height: 'inherit',
              fontSize: 10,
              fontWeight: 500,
              padding: 0
            }}
          >
            {t('reply')}
          </Button>
        )}
      </p>
      <style jsx>{`
        .comment {
          font-size: 13px;
          padding-left: ${isChild ? '8px' : '0'};
          background: ${isNewMessage ? hexToRgba(color, 0.4) : 'transparent'};
          transition: background 4s linear;
          will-change: background;
          margin: 0 0 4px;
        }
        .comment__content {
          margin: 0;
        }
        .content {
          opacity: 0.7;
          margin-right: 8px;
        }
        .author {
          text-transform: uppercase;
          font-weight: 500;
          margin-right: 8px;
        }
        .datetime {
          font-size: 11px;
          opacity: 0.5;
          margin-right: 8px;
          background: ${isNewMessage ? hexToRgba(color, 0.4) : 'transparent'};
        }
      `}</style>
    </section>
  );
};

export default memo(Comment);
